import { Card, Col, Form, Row, Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import { formatNumber } from "../../util/customFormatter";
import { positiveOrZero } from "./FormProyecto";

const initialUnidad = {
    id_categoria: "",
    id_area:"",
    id_tipologia: "",
    tipologia_completa:"",
    descripcion: "",
    superficie: "",
    honorarios:"0",
    indice_calculo:"0",
    num_unidades: "1",
}
const porcentaje_comision=0.06;

const TabDatosProyecto = ({
    datos,
    datosCalculoGeneral,
    errors,
    tiposProyecto,
    uniqueAreas,
    tipologiasFiltered,
    uniqueAreasGeneral,
    tipologiasFilteredGeneral,
    unidadSelected,
    editIndex,
    errorUnidadForm,
    errorDatosCalculoForm,
    handleInputChange,
    setUnidadSelected,
    handleInputDatosCalculo,
    handleAddUnidad,
    handleEditUnidad,
    handleSaveEdit,
    handleDeleteUnidad,
    recalcularHonorariosTotal,
    handleFormat,
    tipoChanged,
    requiereCalcular,
    setRequiereCalcular,
    requiereUnidades,
    incluyePlanoEsq,
    esLote,
    isCheckedEspecial,
    handleCheckboxChange,
    tiposEspecialesVisado,
    handleTipoLoteChange,
    handleRequiereUnidades,
    handleInluyePlanoEsq,
    tipoProceso,
    permitirCambios,
    handleChangeDescuento,
    setDatos,
    setErrors,
    esResellado,
    totalPago,
    currentProyecto
}) => {
    
    const tipologias = useSelector(state => state.tipologia.tipologias || []);
    const categorias = useSelector(state => state.categoria.categorias || []);
    const rubros = useSelector(state => state.areaProyecto.areas || []);
    const { rolActivo } = useSelector((state) => state.sesion); // Obtener el rol del usuario
  
    const handleInputChangeUnidadSelected = (name, value ) => {

        if(name === "id_tipologia" && 
            (unidadSelected.id_categoria === "" || unidadSelected.id_area === "")
        ) {

            const selectedTipologia = tipologias.find(tip => `${tip.id}` === `${value}`);
            setUnidadSelected((prevDatos) => ({ ...prevDatos, [name]: value,
                                                id_categoria: selectedTipologia.categoria.id,
                                                id_area: selectedTipologia.area.id,
             }))
        }
        else if(name === "id_categoria")
           { if(value === "") {
          
                    setUnidadSelected((prevDatos) => ({ ...prevDatos, [name]: value,
                                                        id_tipologia: "",
                                                        id_area: "",
                                                        costo_mes:null
                    }))
                }else{
                    const selectedCategoria= categorias.find(cat => `${cat.id}` === `${value}`);
                    setUnidadSelected((prevDatos) => ({ ...prevDatos, [name]: value,
                        id_tipologia: "",
                        id_area: "",
                        costo_mes:selectedCategoria?.costo_mes
                    }))
                }
            }
            else if(name === "id_area" && value === "") {
                setUnidadSelected((prevDatos) => ({ ...prevDatos, [name]: value,
                                                    id_tipologia: ""
                }))
            }
        else if (name === "num_unidades") {
            // Solo acepta números enteros positivos
            const validValue = /^[1-9]\d*$/.test(value); // Para aceptar solo números enteros positivos
            if (validValue || value === "") {
                // Si el valor es válido o está vacío, actualiza el estado
                setUnidadSelected((prevDatos) => ({ ...prevDatos, [name]: value }));
            }
        }
        else if (name === "descripcion") {
            const hasLetter = /[a-zA-Z]/.test(value) || value==="";
            const isValidLength = value.length <= 15;

            if (hasLetter && isValidLength) {
                // Si el valor es válido o está vacío, actualiza el estado
                setUnidadSelected((prevDatos) => ({ ...prevDatos, [name]: value }));
            }
        }
        else{ setUnidadSelected((prevDatos) => ({ ...prevDatos, [name]: value }));}
        if(!requiereUnidades) 
            setRequiereCalcular(true)
    };



            const handleCheckboxVivienda= ( checked ) => {

                    const { errorViviendaPropia, conceptoDescuento, descuento } = handleChangeDescuento("viviendapropia", checked)

                    setDatos((prevDatos) =>({
                        ...prevDatos,
                        conceptoDescuento,
                        descuento
                    }))
            setErrors(prevE=>({...prevE, viviendaPropia:errorViviendaPropia}))
            }
console.log("datos.tipologia?.id_area", datos.tipologia?.id_area)


const permititViviendaPropia = datos.tipo?.alias !== "urbanizacion" && datos.tipo?.alias !== "viviendasocial"
const permitirSuperficieAprobada =  datos.tipo?.alias === "ampliacion" || datos.tipo?.alias === "levantamiento" || (esResellado && datos.observacion?.includes("Antecedente")) || esResellado
const tipoProyectoSupAprobada = datos.tipo?.alias === "ampliacion" || datos.tipo?.alias === "levantamiento"
const supTotal = (parseFloat(datos.superficie_aprobada || 0) + parseFloat(datos.superficie_total||0)).toFixed(2)    
return (
    <>  
        <Form.Label>DATOS DEL PROYECTO</Form.Label>
        <div className="rounded-2 mb-3" style={{border:"1px solid #dfdfdf", padding:"10px 15px"}}>
                        <Row >
                            <Col md={7}>
                            <Row>
                            <Col md={7} className='col-form'>
                                <Form.Label>Tipo de Proyecto</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="id_tipo_proyecto"
                                    value={datos.id_tipo_proyecto}
                                    onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                    isInvalid={errors.id_tipo_proyecto}
                                    disabled={(esResellado && !datos.mostrarAntecedentes) || !permitirCambios}
                                >
                                    <option value="" disabled>Selecciona un tipo de proyecto</option>
                                    {tiposProyecto.map(tipo => (
                                    <option key={tipo.id} value={tipo.id}>{tipo.nombre}</option>
                                    ))}
                                </Form.Control>
                                    <Form.Control.Feedback type='invalid'>
                                        Por favor selecciona un tipo de proyecto.
                                    </Form.Control.Feedback>
                                {tipoChanged && <button 
                                        className="button-mine btn-mine-tertiary mt-2" 
                                        onClick={recalcularHonorariosTotal} 
                                        type="button"
                                    > 
                                   <i className="bi bi-calculator"></i>  Aplicar y calcular totales
                                    </button>}
                                    {errors.recalcularHonorarios && <div className="text-center text-danger fw-bold">
                                       ** Debe recalcular los honorarios para guardar el proyecto
                                </div>}
                                </Col>
                                <Col md={5} >
                                <Form.Group>
                                    <Form.Label>Carácter</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        name="descripcion"
                                        placeholder="Carácter del proyecto"
                                        value={datos.descripcion}
                                        onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                        isInvalid={errors.descripcion}
                                        rows={4} // Optional: sets the height of the textarea
                                        disabled={isCheckedEspecial || !permitirCambios}
                                    />
                                <Form.Control.Feedback type="invalid">
                                    Por favor ingresa una descripción del proyecto.
                                </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            </Row>
                            <Row>
                          {datos.observacion &&   <Col md={6} >
                                <Form.Group>
                                    <Form.Label>Proceso especial</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="observacion"
                                        placeholder="Proceso del proyecto"
                                        value={datos.observacion}
                                        disabled={true}
                                    />
                                </Form.Group>
                            </Col>}
                            <Col md={6} >
                                <Form.Group>
                                    <Form.Label>Observación</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        name="comentario1"
                                        placeholder="Observación del proyecto"
                                        value={datos.comentario1}
                                        onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                    />
                                </Form.Group>
                                {errors.comentario1&& (
                                <div className="text-danger mt-2 fw-bold">
                                ** La observación es requerida en este caso
                                </div>
                            )}
                                
                            </Col> 
                            </Row>
                            </Col>
                            <Col md={5}>
                            <Form.Label>Cuadro de cálculo</Form.Label>
                            <div>        
                            <Card  style={{backgroundColor:"#fffbe2"}} className={`shadow-sm ${errors.superficie? 'border-danger' : ''}`}>
                                <Card.Body>
                                {tipoProceso === "resellar" &&<> 
                                 <Row className="mb-2">
                                    <Col><strong>Anterior Superficie:</strong></Col>
                                    <Col className="text-end">{formatNumber(datos.proyectoPrevio?.superficie_total)} m2</Col>
                                </Row>
                              <Row className="mb-2">
                                    <Col><strong>Anterior Honorarios:</strong></Col>
                                    <Col className="text-end">{formatNumber(datos.proyectoPrevio?.honorarios_total)} Bs</Col>
                                </Row>
                                <Row>
                                    <Col><strong>Anterior Visación:</strong></Col>
                                    <Col className="text-end">{formatNumber(datos.proyectoPrevio?.comision_cat)} Bs</Col>
                                </Row>
                                <hr className="my-3" /> {/* Línea divisora */}
                                </>}
                                <Row className={`mb-2 ${errors.superficies? 'text-danger' : ''}`}>
                                    <Col><strong>Superficie:</strong></Col>
                                    <Col className="text-end" >{formatNumber(datos.superficie_total)} m2</Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col><strong>Honorarios:</strong></Col>
                                    <Col className="text-end">{formatNumber(datos.honorarios_total)} Bs</Col>
                                </Row>
                                <Row>
                                    <Col><strong>Visación:</strong></Col>
                                    <Col className="text-end">{formatNumber(datos.comision_cat)} Bs</Col>
                                </Row>
                                <Row>
                                    <Col><strong>Monto a pagar:</strong></Col>
                                    <Col className="text-end">{formatNumber(totalPago.totalConDescuento)} Bs</Col>
                                </Row>
                                </Card.Body>
                            </Card>
                          
                           {(permitirSuperficieAprobada) && <Card  style={{backgroundColor:"#f6f0c6"}} className={`shadow-sm mt-2 ${errors.superficie? 'border-danger' : ''}`}>
                                <Card.Body>
                               
                                <Row className={`mb-2`}>
                                    <Col><strong>Superficie Aprob. Prev.:</strong></Col>
                                    <Col className="text-end" >{formatNumber(datos.superficie_aprobada)} m2</Col>
                                </Row>
                                <Row className={`mb-2`}>
                                    <Col><strong>Superficie Total:</strong></Col>
                                    <Col className="text-end" >{`${formatNumber(supTotal)} m2`}</Col>
                                </Row>
                                </Card.Body>
                            </Card>}
                            {errors.superficie ? (
                                <div className="text-danger mt-2 fw-bold">
                                ** La superficie no puede ser 0 al aprobar el visado
                                </div>
                            ): errors.superficies &&(
                                <div className="text-danger mt-2 fw-bold">
                                ** La "superficie del cálculo" debe coincidir con la "superficie por aprobar introducida"
                                </div>
                            )}
                            </div>

                            </Col>
                            </Row>
              <Row className="mt-2">
                        <Col>
                            {/* {(!currentProyecto ||(currentProyecto && tipoProceso==="duplicar")) && (rolActivo?.alias === 'gerente' || rolActivo?.alias === 'secretaria') && (
                    
                               
                                       <Form.Check
                                       type="checkbox"
                                       label="Registrar antecedentes"
                                       checked={datos.mostrarAntecedentes || false}
                                                       onChange={(e) =>
                                                           handleInputChange("mostrarAntecedentes", e.target.checked)
                                                       }
                                    /> 
                               )} */}
                           </Col>
                           <Col  xs={6} lg={3} className={`soft-labels`}>
                                    {(permitirSuperficieAprobada && !datos.mostrarAntecedentes) && <Form.Group controlId="superficie_aprobada">
                                         <Form.Label>Sup. Aprobada Prev.(m2)</Form.Label>
                                         <Form.Control
                                              type="number"
                                              step="0.01"
                                              placeholder="0,00"
                                             name="superficie_aprobada"
                                             value={datos.superficie_aprobada}
                                             onChange={(e) => permitirCambios? handleInputDatosCalculo(e.target.name, e.target.value): null}
                                             disabled={!permitirCambios}
                                         />
                                        {errors.superficie_aprobada && <div className="text-danger mt-2 fw-bold">
                                               * La superficie aprobada previamente solo puede disminuir o mantenerse * 
                                         </div>}
                                     </Form.Group>
                                     }
                            </Col>

                                 </Row>
                           {/* Mostrar campos adicionales si el checkbox está marcado */}
                    {/* {datos.mostrarAntecedentes && (
                    
                    <Row
                                            
                    className={`my-3 mx-1 rounded-3  soft-labels ${errors.unidades ? 'border-danger' : ''}`}
                    style={{padding:"10px 7px", border:"1px solid #dfdfdf"}}
                        >
                              <Col xs={12} lg={4} className="mb-3">
                                <Form.Group>
                                <Form.Label>Tipo de Proceso Especial</Form.Label>
                                    <Form.Select
                                        value={datos.observacion || "- Sin seleccionar -"}
                                        onChange={(e) => handleInputChange("observacion", e.target.value)}
                                    >
                                        <option value="">- Sin seleccionar -</option>
                                        <option value="Resellado de Antecedente">Resellado de Antecedente</option>
                                        <option value="Transferencia C/V de Antecedente">Transferencia C/V de Antecedente</option>
                                        <option value="Pase Profesional de Antecedente">Pase Profesional de Antecedente</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col xs={6} lg={3}>
                            {!currentProyecto?.num_visado &&  <Form.Group>
                                <Form.Label>Número de Visado</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder=""
                                    value={datos.num_visado || ''}
                                    onChange={(e) => handleInputChange("num_visado", e.target.value)}
                                />
                                </Form.Group>}
                            </Col>
                            <Col xs={6} lg={3}>
                               {(datos.observacion === "Resellado de Antecedente" || tipoProyectoSupAprobada)&&<Form.Group>
                                <Form.Label>Sup. Aprobada Prev. (m2)</Form.Label>
                                <Form.Control
                                    type="number"
                                    placeholder="0.00"
                                    value={datos.superficie_aprobada || ''}
                                    onChange={(e) => handleInputDatosCalculo("superficie_aprobada", e.target.value)}
                                />
                                </Form.Group>}
                            </Col>
                            </Row>
                    )} */}
                            {!esLote && (
                            <>
                             {errorDatosCalculoForm !== "" && <p className="text-danger">* {errorDatosCalculoForm} *</p>}
                        
                            <Row
                            
                            className={`my-3 mx-1 rounded-3 ${errors.unidades ? 'border-danger' : ''}`}
                            style={{padding:"10px 7px", border:"1px solid #dfdfdf"}}
                              >
                                  <Col xs={12} md={4} lg={2} className="col-mb-3 soft-labels">
                                      <Form.Group controlId="categoria">
                                          <Form.Label>Categoría</Form.Label>
                                          <Form.Control
                                              as="select"
                                              name="id_categoria"
                                              value={datosCalculoGeneral.id_categoria}
                                              onChange={(e) => permitirCambios? handleInputDatosCalculo(e.target.name, e.target.value): null}
                                              disabled={(esResellado && !datos.mostrarAntecedentes) || !permitirCambios}
                                          >
                                              <option value="">Selecciona una categoría</option>
                                              {categorias.map((cate) => (
                                                  <option key={cate.id} value={cate.id}>
                                                      {cate.nombre}
                                                  </option>
                                              ))}
                                          </Form.Control>
                                      </Form.Group>
                                  </Col>
                                  <Col xs={12} md={4} lg={3} className="mb-3 soft-labels">
                                      <Form.Group controlId="rubro">
                                          <Form.Label>Rubro</Form.Label>
                                          <Form.Control
                                              as="select"
                                              name="id_area"
                                              value={datosCalculoGeneral.id_area}
                                              onChange={(e) => permitirCambios? handleInputDatosCalculo(e.target.name, e.target.value): null}
                                              disabled={(esResellado && !datos.mostrarAntecedentes) || !permitirCambios}
                                          >
                                              <option value="">Selecciona un rubro</option>
                                              {uniqueAreasGeneral.map((area) => (
                                                  <option key={area.id} value={area.id}>
                                                      {area.nombre}
                                                  </option>
                                              ))}
                                          </Form.Control>
                                      </Form.Group>
                                  </Col>
                                  <Col xs={12}  md={4} lg={3}  className="mb-3 soft-labels">
                                      <Form.Group controlId="tipologia">
                                          <Form.Label>Tipología</Form.Label>
                                          <Form.Control
                                              as="select"
                                              name="id_tipologia"
                                              value={datosCalculoGeneral.id_tipologia}
                                              onChange={(e) => permitirCambios? handleInputDatosCalculo(e.target.name, e.target.value): null}
                                              disabled={(esResellado && !datos.mostrarAntecedentes) || !permitirCambios}
                                          >
                                              <option value="">Selecciona una tipología</option>
                                              {tipologiasFilteredGeneral.map((tipolo) => (
                                                  <option key={tipolo.id} value={tipolo.id}>
                                                      {tipolo.descripcion}
                                                  </option>
                                              ))}
                                          </Form.Control>
                                      </Form.Group>
                                  </Col>
                                  <Col  xs={3} lg={1} className="mb-3 soft-labels">
                                    <Form.Group controlId="tipologia">
                                        <Form.Label>CM</Form.Label>
                                        <div className="bg-light">
                                            {datosCalculoGeneral.costo_mes ? formatNumber(datosCalculoGeneral.costo_mes) : "—"}
                                        </div>
                                        </Form.Group>

                                    </Col>
                                    <Col  xs={3} lg={1} className="mb-3 soft-labels">
                                    <Form.Group controlId="tipologia">
                                        <Form.Label>IC</Form.Label>
                                        <div className="bg-light">
                                            {datosCalculoGeneral.indice_calculo?formatNumber(datosCalculoGeneral.indice_calculo) : "—"}
                                            </div>
                                    </Form.Group>
                                    </Col>
                             
                                  <Col  xs={6} lg={2} className={`soft-labels ${errors.superficies? 'text-danger' : ''}`}>
                                      <Form.Group controlId="superficie">
                                          <Form.Label>Sup. por Aprobar (m2)</Form.Label>
                                          <Form.Control
                                          className={`${errors.superficies? 'text-danger' : ''}`}
                                               type="number"
                                               step="0.01"
                                               placeholder="0,00"
                                              name="superficie"
                                              value={datosCalculoGeneral.superficie}
                                              onChange={(e) => permitirCambios? handleInputDatosCalculo(e.target.name, e.target.value): null}
                                              disabled={!permitirCambios}
                                              onBlur={handleFormat}
                                          />
                                      </Form.Group>
                                  </Col>
                                  <Col md={12} className="d-flex justify-content-end">
                                    {requiereCalcular&&
                                          <button 
                                          className="button-mine btn-mine-tertiary" 
                                          onClick={(e) => permitirCambios? recalcularHonorariosTotal(e):null} 
                                          disabled={!permitirCambios}
                                          type="button"
                                      >
                                         <i className="bi bi-calculator"></i>  {requiereUnidades? "Aplicar y calcular IC" : "Calcular totales"}
                                      </button>
                                    }
                                      {errors.calcularHonorarios && <div className="text-center text-danger fw-bold">
                                        ** Debe calcular los honorarios para guardar el proyecto
                                </div>}
                                  </Col>
                              </Row>
                       </> )}
                       {esLote &&
                        (
                            <Row
                            xs={1}
                            md={3}
                            className={`mb-5 ${errors.
                                tipoLote ? 'border-danger' : ''}`}
                            style={{padding:"0px 15px"}}
                        >
                           <Col></Col>
                            <Col>
                                <Form.Check
                                    type="checkbox"
                                    id="lote-especial-checkbox"
                                    name="lote_especial"
                                    label="Visado especial de lote"
                                    onChange={handleCheckboxChange}
                                    checked={isCheckedEspecial}
                                    disabled={esResellado || !permitirCambios}
                                />
                                
                               {isCheckedEspecial && 
                                 <>
                                 <Form.Control
                                 as="select"
                                 className="form-control"
                                 value={datos.tipo_lote}
                                 name='tipo_lote'
                                 onChange={(e) => handleTipoLoteChange(e.target.name, e.target.value)}
                                 disabled={esResellado || !permitirCambios}
                                 >
                                 <option value="" disabled>- Selecciona una opción -</option>
                                 {tiposEspecialesVisado?.map(especial => (
                                 <option value={especial.alias}>{especial.nombre}</option>  
                               ))}
 
                                 </Form.Control>
                                
                                {errors.tipoLote && (
                                    <p className="text-danger mt-2 fw-bold">
                                        ** Debe seleccionar una opción.
                                    </p>
                                )}
                                 </>
                             }
                            </Col>
                            {!isCheckedEspecial &&
                              <Col className="soft-labels">
                              <Form.Group controlId="superficie">
                                  <Form.Label>Superficie por aprobar (m2)</Form.Label>
                                  <Form.Control
                                      type="number"
                                      step="0.01"
                                      placeholder="0.00"
                                      name="superficie_total"
                                      value={datos.superficie_total}
                                      onChange={(e) => handleTipoLoteChange(e.target.name, e.target.value)}
                                      onBlur={handleFormat}
                                      disabled={!permitirCambios}
                                  />
                              </Form.Group>
                          </Col>}
                        </Row> 
                        )}
                          <Row>
                          
                                <Col md={4}>
                                {!esLote &&   
                                        <Form.Check
                                                type="checkbox"
                                                id="unidades-checkbox"
                                                label="Tiene unidades"
                                                onChange={handleRequiereUnidades}
                                                checked={requiereUnidades}
                                                disabled={!permitirCambios}
                                            />
                                        }
                                        </Col>
                              {permititViviendaPropia &&
                                        <Col md={4}>
                                        <Form.Check
                                            type="checkbox"
                                            id="vivienda-propia-checkbox"
                                            label="Vivienda propia"
                                            onChange={(e)=>handleCheckboxVivienda(e.target.checked)}
                                            checked={datos.conceptoDescuento === "viviendapropia"}
                                        />
                                            {errors.viviendaPropia && (
                                            <p className="text-danger mt-2">
                                                * El arquitecto debe ser propietario para habilitar el descuento por Vivienda Propia (No aplicable en transferencia de propietario(s)) *
                                            </p>
                                            )}
                                    </Col>}
                              
                                        <Col md={4}>
                                        {!esLote &&
                                            <Form.Check
                                                    type="checkbox"
                                                    id="plano-esquematico-checkbox"
                                                    label="Incluir Plano Esquemático de Instalaciones"
                                                    title="Aumenta un 25% el costo de la visación"
                                                    onChange={handleInluyePlanoEsq}
                                                    checked={incluyePlanoEsq}
                                                    disabled={!permitirCambios}
                                                />
                                    }
                                </Col>
                            </Row> 
                       </div>
                        {requiereUnidades && !esLote && <>
                           <Form.Label>UNIDADES</Form.Label>
                        <div className="rounded-2" style={{border:"1px solid #dfdfdf", padding:"10px 15px"}}> 
                             <div className="form-container mx-auto p-3 border m-1 rounded-5" style={{backgroundColor:editIndex === null ? "#fff3e5" :"#f4faff" }}>
                             <Form.Label>REGISTRO DE UNIDAD</Form.Label>
                             {errorUnidadForm !== "" && <p className="text-danger">* {errorUnidadForm} *</p>}
                                <Row
                                    xs={1}
                                    md={3}
                                    className={`${errors.unidades ? 'border-danger' : ''}`}
                                    style={{padding:"0px 15px"}}
                                >
                                    <Col className="mb-3 soft-labels">
                                        <Form.Group controlId="categoria">
                                            <Form.Label>Categoría</Form.Label>
                                            <Form.Control
                                                as="select"
                                                name="id_categoria"
                                                value={unidadSelected.id_categoria}
                                                onChange={(e) => handleInputChangeUnidadSelected(e.target.name, e.target.value)}
                                            >
                                                <option value="">Selecciona una categoría</option>
                                                {categorias.map((cate) => (
                                                    <option key={cate.id} value={cate.id}>
                                                        {cate.nombre}
                                                    </option>
                                                ))}
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col className="mb-3 soft-labels">
                                        <Form.Group controlId="rubro">
                                            <Form.Label>Rubro</Form.Label>
                                            <Form.Control
                                                as="select"
                                                name="id_area"
                                                value={unidadSelected.id_area}
                                                onChange={(e) => handleInputChangeUnidadSelected(e.target.name, e.target.value)}
                                            >
                                                <option value="">Selecciona un rubro</option>
                                                {uniqueAreas.map((area) => (
                                                    <option key={area.id} value={area.id}>
                                                        {area.nombre}
                                                    </option>
                                                ))}
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col className="mb-3 soft-labels">
                                        <Form.Group controlId="tipologia">
                                            <Form.Label>Tipología</Form.Label>
                                            <Form.Control
                                                as="select"
                                                name="id_tipologia"
                                                value={unidadSelected.id_tipologia}
                                                onChange={(e) => handleInputChangeUnidadSelected(e.target.name, e.target.value)}
                                            >
                                                <option value="">Selecciona una tipología</option>
                                                {tipologiasFiltered.map((tipolo) => (
                                                    <option key={tipolo.id} value={tipolo.id}>
                                                        {tipolo.descripcion}
                                                    </option>
                                                ))}
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col className="soft-labels">
                                        <Form.Group controlId="descripcion">
                                            <Form.Label>Nombre de la unidad</Form.Label>
                                          <Form.Control
                                                type="text"
                                                name="descripcion"
                                                title="Ejemplo: planta baja, piso tipo, estacionamiento, etc."
                                                placeholder="Nombre de la unidad"
                                                value={unidadSelected.descripcion}
                                                onChange={(e) => handleInputChangeUnidadSelected(e.target.name, e.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col className="soft-labels">
                                        <Form.Group controlId="num_unidades">
                                            <Form.Label>Cantidad de repetidas</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="0"
                                                name="num_unidades"
                                                value={unidadSelected.num_unidades}
                                                onChange={(e) => handleInputChangeUnidadSelected(e.target.name, e.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col className="soft-labels">
                                        <Form.Group controlId="superficie">
                                            <Form.Label>Superficie/unidad (m2)</Form.Label>
                                            <Form.Control
                                                type="number"
                                                step="0.01"
                                                placeholder="0.00"
                                                name="superficie"
                                                value={unidadSelected.superficie}
                                                onChange={(e) => handleInputChangeUnidadSelected(e.target.name, e.target.value)}
                                                onBlur={handleFormat}
                                            />
                                        </Form.Group>
                                    </Col>
                                 
                                    <Col md={12} className="d-flex justify-content-end mt-2">
                                        {editIndex === null ? (
                                            <button
                                                className="button-mine  btn-presuccess"
                                                onClick={handleAddUnidad}
                                                type="button"
                                                disabled={!permitirCambios}
                                            >
                                                <i className="bi bi-plus-circle"></i> Agregar Unidad
                                            </button>
                                        ) : (
                                            <>
                                            
                                            <button
                                                className="button-mine btn-light-blue-outline"
                                                onClick={handleSaveEdit}
                                                type="button"
                                                disabled={!permitirCambios}
                                            >
                                                <i className="bi bi-save"></i> Guardar Edición
                                            </button>
                                              {errors.formUnidad && (
                                                <p className="text-danger mt-2 fw-bold">
                                                    ** Debe guardar la edición.
                                                </p>
                                            )}
                                            </>
                                       
                                        )}
                                       
                                    </Col>
                                  
                                </Row>
                            </div>
                          
                                {/* Tabla para mostrar las unidades registradas */}
                        <div className='table-container'>
                        <Table
                            bordered 
                            hover 
                            className={`mt-4`}
                        >
                            <thead className='text-center align-middle'>
                                <tr>
                                    <th style={{width:"23%"}} title="Categoría/Rubro/Tipología de la unidad" >Tipología</th>
                                    <th title="Nombre de la unidad">Nombre</th>
                                    <th title="Veces que se repite la unidad">Cantidad</th>
                                    <th title="Superficie por unidad">Sup/u (m2)</th>
                                    <th title="Costo Mes">CM</th>
                                    <th title="Índice de cálculo">IC</th>
                                    <th title="Honorarios por la 1ra unidad (Bs)">Honorarios 1ra unidad (Bs)</th>
                                    <th title="Honorarios calculados por todas las unidades repetidas">Subtotal Honorarios (Bs)</th>
                                    <th title="Visación subcalculada">Aporte visación (Bs)</th>
                                    <th style={{width:"8%"}}></th>
                                </tr>
                            </thead>
                            <tbody>
                            {Array.isArray(datos.unidades) && !errors.unidades?
                            (datos.unidades.map((unidad, index) => {
                                // console.log("")
                                const backColor = `${index}` === `${editIndex}`? "#bde1ff":null
                                return(
                                <tr key={index} style={{backgroundColor:backColor}}>
                                    <td style={{backgroundColor:backColor}}>{`${unidad.tipologia?.categoria.nombre} CATEGORÍA / ${unidad.tipologia?.area.nombre} / ${unidad.tipologia?.descripcion}`}</td>
                                    <td style={{backgroundColor:backColor}}>{unidad.descripcion}</td>
                                    <td style={{backgroundColor:backColor}}>{unidad.num_unidades}</td>
                                    <td style={{backgroundColor:backColor}}>{formatNumber(unidad.superficie)}</td>
                                    <td style={{backgroundColor:backColor}}>{formatNumber(unidad.costo_mes)}</td>
                                    <td style={{backgroundColor:backColor}}>{formatNumber(unidad.indice_calculo)}</td>
                                    <td style={{backgroundColor:backColor}}>{formatNumber(unidad.honorariosPrimera)}</td>
                                    <td style={{backgroundColor:backColor}}>{formatNumber(unidad.honorarios)}</td>
                                    <td style={{backgroundColor:backColor}}>{formatNumber(unidad.aporteVisacion)}</td>
                                    <td style={{backgroundColor:backColor}}>
                                    {!backColor && <>
                                        <button 
                                            type="button"
                                            onClick={() => handleEditUnidad(index)}
                                            className="icon-button btn-transparent"
                                            aria-label="Editar unidad">
                                                <i className="bi bi-pencil"></i>
                                        </button>
                                        <button 
                                            type="button"
                                            onClick={() => handleDeleteUnidad(index)}
                                            className="icon-button btn-transparent"
                                            aria-label="Eliminar unidad">
                                                <i className="bi bi-trash"></i>
                                        </button>
                                    </>}
                                    
                                    </td>
                                </tr>
                            )})):(
                                <tr>
                                <td colSpan="7" className="text-center text-danger">
                                    No hay unidades registradas.
                                </td>
                            </tr>
                            )}

                            </tbody>
                        </Table>
                        {errors.unidades && (
                            <p className="text-danger mt-2 fw-bold">
                         ** Debe registrar al menos una unidad para enviar el proyecto a Revisión.
                            </p>
                        )}
                        </div></div>
                        </>}
                     
    </>
    );
    }

    export default TabDatosProyecto;