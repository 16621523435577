import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import jsPDF from 'jspdf';
import cabeceraCert from '../../assets/cabeceraCat.png';
import { useSelector } from 'react-redux';

const CustomPrintCert2 = ({ show, handleClose, afiliado, numeroCertificado, aserv, qrDataURL, certObjectText, copia }) => {
    const [pdfUrl, setPdfUrl] = useState(null);
    const { usuario } = useSelector(state => state.sesion);

    useEffect(() => {
        if (show) {
            generatePDF();
        }
    }, [show]);

    const generatePDF = () => {
        const doc = new jsPDF({ orientation: 'portrait', unit: 'px', format: 'letter' });
        const pageWidth = doc.internal.pageSize.getWidth();
        const pageHeight = doc.internal.pageSize.getHeight();
        const marginTop = 40;
        const marginLeftRight = 40;

        const day = new Date().getDate();
        const month = new Date().toLocaleString('es-ES', { month: 'long' });
        const year = new Date().getFullYear();

        const pronoun = afiliado.persona.genero === 'FEMENINO' ? 'la' : 'el';
        const pronounInteresado = afiliado.persona.genero === 'FEMENINO' ? 'de la interesada' : 'del interesado';
        const inscritoHabilitado = afiliado.persona.genero === 'FEMENINO' ? 'inscrita' : 'inscrito';
        const habilitadoContextual = afiliado.persona.genero === 'FEMENINO' ? 'habilitada' : 'habilitado';

        // Marca de agua si es copia (B O R R A D O R)
        if (copia) {
            doc.setFontSize(60);
            doc.setTextColor(200, 200, 200);
            doc.text('S I N   V A L I D E Z', pageWidth / 2, pageHeight / 2, {
                align: 'center',
                angle: 10,
            });
        }
        doc.setTextColor(10, 10, 10);
        // Cabecera
        doc.addImage(cabeceraCert, 'PNG', marginLeftRight, marginTop, pageWidth - marginLeftRight * 2, 50);

        // Número de Certificado
        doc.setFont('times', 'bold');
        doc.setFontSize(12);
        doc.text(`Nº ${numeroCertificado}`, pageWidth - marginLeftRight, marginTop + 20, { align: 'right' });

        // Título
        doc.setFont('times', 'bold');
        doc.setFontSize(18);
        doc.text('CERTIFICADO DE INSCRIPCIÓN', pageWidth / 2, marginTop + 80, { align: 'center' });

        doc.setFont('times', 'italic');
        doc.setFontSize(10);
        doc.text('(Decreto Supremo Nº 25905 de 15 de Septiembre de 2000)', pageWidth / 2, marginTop + 95, { align: 'center' });

        // Texto del Colegio
        doc.setFont('times', 'normal');
        doc.setFontSize(12);
        doc.text('El Colegio de Arquitectos de Tarija.', marginLeftRight, marginTop + 120);

        // Certificación
        doc.setFont('times', 'bold');
        doc.setFontSize(18);
        doc.text('CERTIFICA:', marginLeftRight, marginTop + 145);

        doc.setFont('times', 'normal');
        doc.setFontSize(12);
        doc.text(
            `Que, ${pronoun} ARQ. ${afiliado.persona.nombre} ${afiliado.persona.apellido1 || ""} ${afiliado.persona.apellido2 || ""}, con C.I. ${afiliado.persona.ci}, Grado académico de ARQUITECTO, está ${inscritoHabilitado} en el Registro Nacional de Arquitectos de Bolivia (Ley 1373 de 13 de noviembre de 1992) desde ${new Date(afiliado.fecha_reg_cab).toLocaleDateString('es-ES')}, con el Registro Nº ${afiliado.num_reg}, encontrándose ${habilitadoContextual} legalmente para ejercer la profesión en todo el territorio nacional.`,
            marginLeftRight,
            marginTop + 165,
            { maxWidth: pageWidth - marginLeftRight * 2, align: 'justify' }
        );

        doc.text(
            `El presente certificado se extiende a solicitud ${pronounInteresado} con el objeto determinado de:`,
            marginLeftRight,
            marginTop + 220,
            { maxWidth: pageWidth - marginLeftRight * 2, align: 'justify' }
        );

        // Espacio designado para el texto dinámico
        const textStartY = marginTop + 245; // Inicio del espacio
        const textEndY = marginTop + 340; // Fin del espacio
        const textHeight = textEndY - textStartY; // Altura del espacio designado

        // Calcular la altura del texto dinámico
        const lineHeight = 12; // Altura de cada línea (en px)
        const lines = doc.splitTextToSize(certObjectText, pageWidth - marginLeftRight * 2); // Divide el texto en líneas
        const totalTextHeight = lines.length * lineHeight; // Altura total del texto

        // Calcular la posición vertical para centrar el texto
        const centeredTextY = textStartY + (textHeight - totalTextHeight) / 2;

        // Dibujar el texto dinámico centrado
        doc.setFont('times', 'bold');
        doc.text(lines, pageWidth / 2, centeredTextY, {
            maxWidth: pageWidth - marginLeftRight * 2,
            align: 'center',
        });
        doc.setFont('times', 'normal');
        doc.text(
            'Es cuanto se certifica en honor a la verdad y para los fines consiguientes.',
            marginLeftRight,
            marginTop + 340,
            { maxWidth: pageWidth - marginLeftRight * 2, align: 'justify' }
        );

        // Fecha
        doc.text(`Tarija, ${day} de ${month} de ${year}`, marginLeftRight, marginTop + 360);

        // COLEGIO DEPARTAMENTAL
        doc.setFont('times', 'bold');
        doc.text('COLEGIO DEPARTAMENTAL DE TARIJA', marginLeftRight, marginTop + 400);

        // Pie
        doc.setFont('times', 'bold');
        doc.setFontSize(8);
        doc.text('El presente certificado tiene validez de 30 días.', pageWidth / 2, pageHeight - 50, { align: 'center' });
        doc.text('Arquitectura Atribución de Arquitectos.', pageWidth / 2, pageHeight - 40, { align: 'center' });

        doc.setFont('times', 'normal');
        doc.setFontSize(8);
        doc.text(
            'Calle La Madrid # 615 casi Esq. Junín - Celular.: (591-4)6640066 - cat.tarija@gmail.com',
            pageWidth / 2,
            pageHeight - 30,
            { align: 'center' }
        );
        doc.text('Tarija - Bolivia', pageWidth / 2, pageHeight - 20, { align: 'center' });

        doc.text(
            `Impreso el: ${new Date().toLocaleString()} | Usuario: ${usuario || 'Desconocido'}`,
            pageWidth - marginLeftRight,
            pageHeight - 10,
            { align: 'right' }
        );

        // QR Code
        if (qrDataURL) {
            doc.addImage(qrDataURL, 'PNG', pageWidth - marginLeftRight - 100, pageHeight - 200, 80, 80);
        }

        const pdfBlob = doc.output('blob');
        const pdfUrl = URL.createObjectURL(pdfBlob);
        setPdfUrl(pdfUrl);
    };

    return (
        <Modal show={show} onHide={handleClose} size="lg" centered>
            <Modal.Header closeButton>
                <Modal.Title>Vista Previa del Certificado</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {pdfUrl ? (
                    <iframe src={pdfUrl} width="100%" height="500px" title="Vista previa del certificado" />
                ) : (
                    <p>Cargando vista previa...</p>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cerrar
                </Button>
                <Button variant="primary" onClick={() => window.open(pdfUrl, '_blank')}>
                    Descargar
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CustomPrintCert2;
