import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, ListGroup, Alert } from "react-bootstrap";
import FormInmueble from "./FormInmueble";
import { inmuebleActions } from "../../reducers/inmueble_slice";
import ActionButton from "../UI/Buttons/ActionButton";

const BuscarInmueble = ({ onSeleccionar }) => {
  const dispatch = useDispatch()
  const [codigo, setCodigo] = useState("");
  const [resultado, setResultado] = useState(null);
  const [mensajeError, setMensajeError] = useState("");
  const [showModalInmueble, setShowModalInmueble] = useState(false);

  const inmuebles = useSelector(state => state.inmueble.inmuebles || []);
  const normalize = (str) => str.replace(/\s+/g, '').replace(/-/g, '');
   

  const handleBuscar = () => {
    const encontrado = inmuebles.find((inmueble) => normalize(inmueble.cod_catastral) === normalize(codigo));
    if (encontrado) {
      setResultado(encontrado);
      setMensajeError(""); // Limpiar mensaje de error si se encuentra
    } else {
      setResultado(null);
      setMensajeError("No se encontró un inmueble con ese código catastral.");
    }
  };

  const handleCreate = () => {
    dispatch(inmuebleActions.setCurrentInmueble(null))
    setShowModalInmueble(true);
  };

   const handleClose = () => {
      setShowModalInmueble(false);
      dispatch(inmuebleActions.setCurrentInmueble(null))
    };
  return (
    <>
    <div className="row">
  
    <div className="col soft-labels">
      <Form.Group>
        <Form.Label>Buscar inmueble existente:</Form.Label>
        <Form.Control
          type="text"
          placeholder="Código catastral completo"
          value={codigo}
          onChange={(e) => setCodigo(e.target.value)}
        />
      </Form.Group>
      <button className="button-mine mt-2 btn-mine-secondary" onClick={handleBuscar} >
        <i className="bi bi-search"></i> Buscar
      </button>
      </div>
      <div className="col">
         <ActionButton onClick={handleCreate} outline icon='plus' floatEnd>
           Nuevo Inmueble
         </ActionButton>
       </div>
      {resultado ? (
        <ListGroup className="mt-3">
          <ListGroup.Item action onClick={() => onSeleccionar(resultado)}>
            {resultado.cod_catastral} - {resultado.zona.nombre} - {resultado.zona.lugar.nombre}
          </ListGroup.Item>
        </ListGroup>
      ) : (
        mensajeError && <Alert variant="danger" className="mt-3">{mensajeError}</Alert>
      )}

    </div>

      <FormInmueble showModal={showModalInmueble} handleClose={handleClose} onSelectInmueble={onSeleccionar}/>
      </>
  );
};

export default BuscarInmueble;
