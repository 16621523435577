import { useEffect, useState } from "react";
import { Form, Button, Modal, Alert, ListGroup } from "react-bootstrap";
import TablePersona from "./TablePersona";
import { useDispatch, useSelector } from "react-redux";
import { fetchPersonas, personaActions } from "../../reducers/persona_slice";
import ActionButton from "../UI/Buttons/ActionButton";
import PersonaForm from "./PersonaForm";
const BuscarPersonas = ({ onSelect, personasSelected = [], permitirEdicion, handleRemovePersona,actualizarListaFn=null, simplePersona=true, setShowModalBuscar}) => {
  const dispatch = useDispatch();
  const [ci, setCi] = useState("");
  const [resultado, setResultado] = useState(null);
  const [mensajeError, setMensajeError] = useState("");
  const personas = useSelector((state) => state.persona.personas || []);
  const [showModalPersona, setShowModalPersona] = useState(false);
   useEffect(() => {
    dispatch(fetchPersonas());
  }, [dispatch]);

  const handleBuscar = () => {
    const personaEncontrada = personas.find((p) => p.ci === ci.trim());
    if (personaEncontrada) {
      if (personasSelected.some((p) => p.id === personaEncontrada.id)) {
        setMensajeError("");
        setResultado({ ...personaEncontrada, yaSeleccionado: true });
      } else {
        setResultado({ ...personaEncontrada, yaSeleccionado: false });
        setMensajeError("");
      }
    } else {
      setResultado(null);
      setMensajeError("No se encontró una persona con ese C.I.");
    }
  };

  const handleAdd = (resultado) => {
    onSelect(resultado);
    setCi(resultado.ci)
    setResultado({ ...resultado, yaSeleccionado: true });
    setMensajeError("")
  };

  const handleRemove = (personaId) => {
    handleRemovePersona(personaId);
    setResultado({ ...resultado, yaSeleccionado: false });
  };

  const handleLimpiar = () => {
    setCi("");
    setResultado(null);
    setMensajeError("");
  };


const handleCreate = () => {
  dispatch(personaActions.setCurrentPersona(null));
  setShowModalPersona(true);
};
const handleClose = () => {
  setShowModalPersona(false);
  dispatch(personaActions.setCurrentPersona(null));
};
  return (
    <>
      <div className="row">
  
  <div className="col soft-labels">
      <Form.Group>
         <Form.Label>Buscar persona existente:</Form.Label>
        <Form.Control
          type="text"
          placeholder="Número C.I. completo"
          value={ci}
          onChange={(e) => setCi(e.target.value)}
        />
      </Form.Group>
      <div className="d-flex gap-2 mt-2">
      <button className="button-mine btn-cancel-outline" onClick={handleLimpiar}>
           Limpiar
        </button>
        <button className="button-mine btn-mine-secondary" onClick={handleBuscar}>
          <i className="bi bi-search"></i> Buscar
        </button>
        </div>      
        </div>
        <div className="col">
        <ActionButton onClick={handleCreate} outline icon='plus' floatEnd type="button">
              Nueva Persona
            </ActionButton>
          </div>

          <ListGroup className="mt-3" style={{backgroundColor:"#f7f7f7"}}>
      {resultado ? (
          <ListGroup.Item
            action={!resultado.yaSeleccionado}
            onClick={!resultado.yaSeleccionado ? () => handleAdd(resultado) : undefined}
            className={resultado.yaSeleccionado ? "text-muted" : ""}
            
          >
            <strong>
              {resultado.nombre} {resultado.apellido1 || ""} {resultado.apellido2 || ""}
            </strong>{" "}
            - C.I.: {resultado.ci}
            {resultado.yaSeleccionado && (
              <span className="text-success ms-2">(Seleccionado)</span>
            )}
              {permitirEdicion && resultado.yaSeleccionado &&(
                            <Button
                              variant="link"
                              onClick={() => handleRemove(resultado.id)}
                              className="btn-sm text-danger"
                            >
                              Quitar
                            </Button>
                          )}
          </ListGroup.Item>
   
      ):(
        <ListGroup.Item style={{height:"40px"}}></ListGroup.Item>
      )}
     </ListGroup>
      {mensajeError && <Alert variant="danger" className="mt-3">{mensajeError}</Alert>}
      </div>
      <PersonaForm currentPersona={null} 
                                simplePersona = {simplePersona}
                                showModal={showModalPersona} 
                                handleClose={handleClose} 
                                actualizarListaFn={actualizarListaFn? actualizarListaFn : fetchPersonas}
                                onSelectPersona={handleAdd}/>
    </>
  );
};

export default BuscarPersonas;
